@import "video_list";

&.account-page {
	#main {
		#video-list-more-infos {
			.block-title {
				color: @text-invert-color;
			}
			.cur-stats {
				color: @text-invert-color;
			}
		}
		.video-count {
			color: @text-invert-color;
			a {
				color: @text-invert-color;
			}
		}
	}
}

&.action-video_list {
	.video-list {
		clear: both;

		.video {
			background: @account-block-bg;

			.video-details {
				color: @text-color;
			}
		}
	}
}