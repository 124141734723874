
@import "bootstrap";
@import "../../libs/datetimepicker";
@import "xnxx_variables";

.account-page {
	@import "xnxx_elements";
	@import "xnxx_modules";
	@import "responsive";
	@import "xnxx_responsive";
	@import "modules/video_statistics";
	
	p a,
	ul a {
		color: #fff;
	}
	#dashboard {
		padding: 20px;
	}
	#header {
		z-index: @zindex-mobile-header;
		position: relative;
		
		#site-nav { //xnxx
			ul {
				li {
					overflow: visible;
					
					.nav-tab-sub-menu {
						background: @blue-theme-light;
						padding: 5px 12px 10px;
						
						.account-section-title {
							opacity: 0.8;
							margin: 0;
							padding: 0.5em 8px 0.5em 10px;
						}
						
						.account-section-nav {
							margin-bottom: 0;
							
							.list-group-item {
								background: fadeOut(@blue-theme-light-border, 25%);
								border-width: 0;
								padding: 8px 10px;
								text-transform: none;
								
								&:hover ,
								&.active {
									background: #417fff;
								}
							}
						}
					}
				}
			}
		}
	}
	
	#main {
		#content {
			.wrapper {
				width: @wrapper-side-menu-width + @wrapper-max-width;
				max-width: 100%;
			}
			#cur-tab-nav{
				display: block;
				width: unit(@wrapper-side-menu-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
				max-width: unit(@wrapper-side-menu-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
			}
			#cur-section-pan{
				width: unit(@wrapper-max-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
				max-width: unit(@wrapper-max-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
			}
			
			.min-height-100vh(300px);
			
			#cur-tab-nav {
				.account-section-title {
					font-size: 17px;
					padding: 12px 8px 12px 18px;
				}
				.account-section-nav {
					a.list-group-item {
						border-width: 0;
						display: block;
						
						.icon-f {
							margin-right: 8px;
						}
					}
				}
			}
		}
	}
}



#content {
	.wrapper {
		box-shadow: 0 0 100px -1px rgba(0, 0, 0, 0.9);
		margin: 0 auto;
		padding: 0 @wrapper-side-padding;
		max-width: @wrapper-max-width;
	}
	h2 {
		font-size: 24px;
		margin: 10px 7px;
	}
}

#account-content-block{
	border-radius: 0;
	border: 0;
	margin-bottom: 0;
	& > .panel-heading{
		border-bottom: 0;
	}
}


#signin-popup-form {
	padding: 0 20px;
}

body.upload-publish-results {
	overflow: hidden;
	padding: 0;
	margin: 0;
}


form,
legend{
	color: #fff;
}
form{
	input + .progress{
		background: @blue-theme-dark;
		border-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 4px;
	}
}


.form-popup-error {
	.x-popup-content{
		padding: 4px 12px;
	}
}

.alert {
	&.alert-danger {
		color: @alert-danger-text;
	}
}



/* Valentine 2023 offer */

@import "../xnxx/marketing/offers/valentine-23";