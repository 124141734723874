//max 1800px
@media(max-width: 1800px) {
	#main {
		#content {
			.wrapper {
				width: @wrapper-side-menu-width + @wrapper-max-width;
				max-width: 100%;
				
				#cur-tab-nav {
					display: block;
					width: unit(@wrapper-side-menu-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
					max-width: unit(@wrapper-side-menu-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
				}
				
				#cur-section-pan {
					width: unit(@wrapper-max-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
					max-width: unit(@wrapper-max-width / (@wrapper-side-menu-width + @wrapper-max-width) * 100, %);
				}
			}
		}
	}
}


@media(min-width: @wrapper-min-width-with-menu) {
	#content {
		//padding-left: @wrapper-side-menu-width;
		min-height: 310px;
	}
	&.action-arony_payment_form,
	&.action-login_create {
		#content {
			padding-left: 0;
		}
	}
}

@media (min-width: @screen-over-all-mobile) {
	
	#header {
		#site-nav {
			ul {
				li {
					.nav-tab-sub-menu {
						box-shadow: 0 9px 6px -6px inset fadeOut(#000, 50%), 6px 6px 6px -5px fadeOut(#000, 50%), -8px 3px 6px -7px fadeOut(#000, 50%);
											}
				}
			}
		}
	}

	#content > .wrapper > .row {
		& > .col-xs-2 {
			box-shadow: 1px 0 0 @account-panel-header-bg;
		}
		& > .col-xs-10 {
			box-shadow: 1px 0 0 inset @account-panel-header-bg;
		}
	}
}

@media (min-width: @screen-sm-min) {
	#account-content-block {
		& > .panel-heading {
			margin-left: -@wrapper-side-padding;
			margin-right: -@wrapper-side-padding;
		}
	}
}

@media (max-width: @screen-all-mobile) {
	#header {
		#site-nav {
			min-height: 0;
			
			#mobile-menu {
				& > ul {
					& > li {
						& > a {
							background: @blue-theme-light;
							border-bottom-width: 0;
							border-top-width: 0;
							font-size: 16px;
							font-weight: bold;
							padding: 14px 18px 0;
							text-align: center;
						}
					}
				}
				
				ul {
					li {
						.nav-tab-sub-menu{
							display: block;
							position: static;
							padding-top: 0;
							
							.account-section-title {
								&:first-child {
									padding-top: 0;
								}
								&:not(:first-child) {
									padding-top: 1em;
								}
							}
						}
					}
				}
			}
		}
	}
	
	#main {
		#content {
			.wrapper {
				#cur-tab-nav {
					display: none;
				}
				
				#cur-section-pan {
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}
}


//@media(min-width: @screen-over-all-mobile) and (max-width: @wrapper-max-width-with-menu) {
//	.xnxx_mobile_nav(72px);
//}
