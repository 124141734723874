@import "video_edit";

&.action-video_edit {
	#main {
		.video-recap {
			h2 {
				color: @text-invert-color;
			}
		}

		#content {
			#video-edit-tabs {
				ul {
					a {
						color: @text-invert-color;
						&.active {
							color: @text-color;
						}
					}
				}
			}
		}

		.video-recap {
			.video-details {
				.video-file-name,
				.label{
					color: @text-color;
				}
				a.btn-default {
					color: @text-invert-color;
				}
			}
		}
	}
}