@import "modules/page";

#main {
	#account-content-block {
		& > .panel-heading {
			h2 {
				.icon-f {
					vertical-align: text-bottom;
				}
			}
		}
	}
}

@import "modules/account";
@import "modules/dashboard";
@import "modules/manage";
@import "modules/support";
@import "modules/premium";
@import "modules/xnxx_video_delete";
@import "modules/xnxx_video_list";
@import "modules/xnxx_video_edit";
@import "modules/xnxx_video_upload";
@import "modules/social_network_signin";

@media (max-width: @screen-all-mobile) {
	&.action-arony_payment_form #header #site-nav {
		display: none;
	}
}

&.action-arony_payment_form {
	#arony-pay-form {
		min-height: 100px;
		padding: 20px;
		
		h2 {
			margin-left: 0;
			margin-right: 0;
		}
		
		h6 {
			line-height: 1.3;
		}
		
		form {
			
			button {
				background: @blue-theme-light;
				border: 0;
				font-weight: bold;
				
				&:hover {
					background: @blue-theme-dark;
				}
			}
			
			.tos.form-group {
				#tos_container {
					label {
						input {
							margin-top: 0
						}
					}
				}
			}
		}
	}
}
