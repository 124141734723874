@import "video_upload";

.tag-adder-pop li {
    background: @account-block-bg;
}

&.action-video_upload,
&.action-video_edit,
&.action-verify_profile {
    #main {
        #video-list-more-infos {
            .cur-stats {
                color: @text-color;
            }
        }
        .alert-warning {
            color: @text-invert-color;
        }
        .form-horizontal {
            &.form-step {
                legend {
                    color: @text-invert-color
                }
            }
            .instructions {
                &.warning {
                    a {
                        color: @text-color;
                    }
                }
            }
            .file-upload-inputs {
                .file-upload-file {
                    label.control-label {
                        span {
                            &.icf-upload {
                                background: @gold-theme;
                                border: 1px solid @account-panel-border;
                            }
                        }
                        &:hover {
                            span {
                                &.icf-upload {
                                    background: darken(@gold-theme, 10%);
                                }
                            }
                        }
                    }
                }
                .file-upload-submit {
                    .input-group {
                        .type {
                            color: @gold-theme;
                        }
                        .btn {
                            background: @gold-theme;
                        }
                    }
                }
            }
        }
    }
}