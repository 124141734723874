@import "video_delete";

#main {
	.form-horizontal {
		&.form-step {
			legend {
				color: @text-invert-color
			}
		}
	}
}