
.signin-offer-ribbon-valentine-23 {
	.offer-ribbon-valentine-23 {
	}
}
#premium-popup-form.signin-offer-ribbon-valentine-23,
.premium-popup-form.signin-offer-ribbon-valentine-23 {
	.offer-ribbon-valentine-23 {
		margin-top: 0;
	}
}
#premium-popup-form.signin-offer-ribbon-valentine-23,
.premium-popup-form.signin-offer-ribbon-valentine-23 {
	&.x-overlay {
		.x-body {
			box-shadow: none;
		}
	}
	
	.offer-ribbon-valentine-23 {
		height: 87px;
		
		.offer-ribbon-valentine-23-back {
			height: 87px;
			
			&::before {
				background: @blue-theme url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-bg.png') center center repeat;
				background-size: cover;
			}
		}
		&::after {
			background-image: linear-gradient(140deg, @blue-theme-darker2 0, @blue-theme-dark 80%);
		}
		
		.offer-ribbon-valentine-23-hook {
			font-size: 20px;
			//line-height: 46px;
			line-height: 22px;
			padding-left: 52px;
			padding-right: 40px;
		}
		
		.offer-ribbon-valentine-23-hook,
		.offer-ribbon-valentine-23-hook-mobile {
			color: #90b1f5;
			text-shadow: none;
			text-transform: uppercase;
		}
		
		.offer-ribbon-valentine-23-annual-xn {
			background: transparent url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-desktop-hearts-left.png') 0 0 no-repeat;
			bottom: -5px;
			color: #fff;
			font-size: 26px;
			font-weight: bold;
			height: 73px;
			left: 32px;
			line-height: 44px;
			padding: 23px 84px 0 82px;
			position: absolute;
			right: 0;
			text-align: center;
			text-transform: uppercase;
			z-index: 3;
			
			.resize {
				white-space: nowrap;
			}
			
			strong {
				color: #ffe15b;
				display: inline-block;
				transform: scale(1.4);
				transform-origin: left 55%;
			}
			
			&::after {
				background: transparent url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-desktop-hearts-right.png') 0 0 no-repeat;
				bottom: 0;
				content: "";
				height: 103px;
				display: block;
				position: absolute;
				right: 0;
				width: 83px;
			}
		}
	}
}


/* ==== max 870px = Intermediate small screens ==== */
@media (max-width: 870px) {
	#premium-popup-form.signin-offer-ribbon-valentine-23,
	.premium-popup-form.signin-offer-ribbon-valentine-23 {
		.offer-ribbon-valentine-23 {
			height: 107px;
			
			.offer-ribbon-valentine-23-back {
				height: 107px;
			}
			
			.offer-ribbon-valentine-23-hook-mobile {
				font-size: 22px;
				padding-left: 58px;
				padding-right: 73px;
			}
			
			.offer-ribbon-valentine-23-annual-xn {
				background: transparent url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-mobile-hearts-left.png') 0 0 no-repeat;
				height: 77px;
				left: 5px;
				padding: 23px 84px 0 58px;
				
				&::after {
					background: transparent url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-mobile-hearts-right.png') 0 0 no-repeat;
					bottom: 16px;
					height: 104px;
					width: 75px;
				}
			}
		}
	}
}


/* ==== max 640px ==== */
@media (max-width: 640px) {
	#premium-popup-form.signin-offer-ribbon-valentine-23,
	.premium-popup-form.signin-offer-ribbon-valentine-23 {
		.offer-ribbon-valentine-23 {
			.offer-ribbon-valentine-23-hook-mobile {
				font-size: 20px;
			}
		}
	}
}

/* ==== max 560px ==== */
@media (max-width: 560px) {
	#premium-popup-form.signin-offer-ribbon-valentine-23,
	.premium-popup-form.signin-offer-ribbon-valentine-23 {
		.offer-ribbon-valentine-23 {
			.offer-ribbon-valentine-23-hook-mobile {
				font-size: 18px;
			}
		}
	}
}

/* ==== max 479px ==== */
@media (max-width: 479px) {
	#premium-popup-form.signin-offer-ribbon-valentine-23,
	.premium-popup-form.signin-offer-ribbon-valentine-23 {
		.offer-ribbon-valentine-23 {
			.offer-ribbon-valentine-23-hook-mobile {
				font-size: 16px;
			}
		}
	}
}



// /gold/videos
#valentine-23-offer-band {
	background: @blue-theme url('../../../v3/img/skins/xnxx/offer/valentine-23/valentine-23-bg.png') center center no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 170px;
	padding: 0 20px;
	margin: 0 -20px -10px;
	width: calc(~"100% + 40px");
	
	& + .mozaique {
		padding-top: 0;
	}
	
	.offer-band-valentine-23-hook,
	.offer-band-valentine-23-ctaline {
		font-size: 24px;
		font-weight: bold;
		display: block;
		margin: 0 auto;
		max-width: 1280px;
		padding: 0 30px;
		text-align: center;
		text-transform: uppercase;
	}
	.offer-band-valentine-23-ctaline {
		align-content: stretch;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		
		.offer-band-valentine-23-annual-xn {
			flex-grow: 50;
			flex-shrink: 100;
			text-align: right;
			
			strong {
				color: @yellow-theme;
				display: inline-block;
				padding-left: 0.2em;
				transform: scale(1.35);
				transform-origin: left 55%;
			}
			
			.resize {
				align-items: center;
				display: flex;
				justify-content: flex-end;
				transform: translateX(-0.6em);
			}
		}
		
		.offer-band-valentine-23-cta {
			flex-grow: 50;
			flex-shrink: 0;
			margin-left: 20px;
			text-align: left;
		}
		
		.btn {
			flex-grow: 0;
			font-size: 14px;
			text-transform: none;
		}
	}
}


/* ==== max 767px ==== */
@media (max-width: 767px) {
	#valentine-23-offer-band {
		.offer-band-valentine-23-hook,
		.offer-band-valentine-23-ctaline {
			font-size: 22px;
		}
		.offer-band-valentine-23-ctaline {
			margin-top: 5px;
		}
	}
}
/* ==== max 560px ==== */
@media (max-width: 560px) {
	#valentine-23-offer-band {
		.offer-band-valentine-23-hook,
		.offer-band-valentine-23-ctaline {
			font-size: 18px;
		}
		.offer-band-valentine-23-hook {
			padding: 0 20px
		}
		.offer-band-valentine-23-ctaline {
			.offer-band-valentine-23-cta {
				margin-left: 10px;
			}
		}
	}
}