@import "elements/base_style";
@import "elements/alerts";
@import "elements/helpers";
@import "elements/forms";
@import "elements/table";
@import "elements/bootstrap_components";
@import "../../common/x-messages.less";

#x-home-messages .x-message.x-message-error {
	background: @brand-danger;
	border-color: @brand-danger;
}
#x-home-messages .x-message.x-message-warning {
	background: @brand-warning;
	border-color: @brand-warning;
}

#x-x-messages-btn{
	background: @x-messages-bg;
	border: 1px solid @x-messages-border;
	.ic {
		color: @x-messages-color;
	}
}

&.action-export_data{
	.overflowable-table-container{
		table{
			white-space: nowrap;
			.expires-link{
				white-space: normal;
			}
		}
	}
}

.bootstrap-datetimepicker-widget {
	box-shadow: 0 1px 5px 1px fade(#000, 60%);
	border-color: fade(#fff, 30%);
}